/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useId, useRef, useState } from 'react'
import AngleDownIcon from '@/icons/bwi/angle-down.svg'
import LabelFloating from './label-floating'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string
  initialValue?: string
  onUpdate?: (arg: string) => void
  tooltip?: string
  children: React.ReactNode
}

export default function SelectField(props: Props): JSX.Element {
  const { label, initialValue, onUpdate, children, tooltip, ...inputProps } = props

  const ref = useRef<HTMLSelectElement>()
  const uid = useId()

  const [hasFocus, setFocus] = useState(false)
  const [input, setInput] = useState('')
  const [search, setSearch] = useState()

  useEffect(() => {
    setSearch(new URLSearchParams(window.location).get('search'))
    const defVal = new URLSearchParams(search).get(inputProps.name) || (initialValue as string)
    if (defVal) setInput(defVal)

    if (document.hasFocus() && ref.current === document.activeElement) setFocus(true)
  }, [])

  useEffect(() => {
    if (onUpdate) onUpdate(input)
  }, [input])

  return (
    <LabelFloating
      label={label}
      uid={uid}
      hasFocus={hasFocus}
      hasInput={input?.length > 0 || !initialValue}
      required={inputProps.required}
      name={inputProps.name}
      tooltip={tooltip}
    >
      {/* @ts-ignore */}
      <select
        ref={ref}
        id={uid}
        value={input}
        onChange={(event) => setInput(event.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className="h-12 lg:h-14 pl-3 pr-6 text-gray-700 w-full outline-none appearance-none bg-inherit truncate float-left"
        {...inputProps}
      >
        {!initialValue && label && (
          <option disabled value="">
            Please select {label.toLocaleLowerCase()}
          </option>
        )}
        {children}
      </select>
      <AngleDownIcon
        className="w-4 h-4 pointer-events-none text-gray-600 transition-transform group-hover:text-black group-hover:scale-125 absolute right-3 top-[calc(50%-8px)]"
        css={[hasFocus && `text-black scale-125`]}
        role="presentation"
        aria-hidden="true"
        focusable="false"
      />
    </LabelFloating>
  )
}
